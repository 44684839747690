//////////////////// DO NOT MODIFY THESE IMPORTS --//
import "../../scss/__av/site.scss";
import "../application";
//-- DO NOT MODIFY THESE IMPORTS ////////////////////

(() => {
  $(document).ready(() => {
    $(".facebookConnect").on("click", (e) => {
      const element = $(e.target);

      if (element.hasClass("disabled")) {
        return;
      }
      $(e.target).addClass("disabled is-loading");

      const permissions = "email, public_profile, user_birthday, user_gender";
      FB.login(
        (response) => {
          if (response.status === "connected") {
            // Logged into your app and Facebook.
            setTimeout(() => {
              window.location = "/facebook_check";
            }, 500);
          } else {
            // The person is not logged into this app or we are unable to tell.
          }
        },
        { scope: permissions }
      );
    });
  });
}).call(window);
